/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$seak-palette: (
  50: #e2eced,
  100: #b6d0d2,
  200: #85b1b5,
  300: #549197,
  400: #307a80,
  500: #0b626a,
  600: #0a5a62,
  700: #085057,
  800: #06464d,
  900: #03343c,
  A100: #72e9ff,
  A200: #3fe0ff,
  A400: #0cd8ff,
  A700: #00cbf1,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);

$tertiary-palette: (
  50 : #e6e8f3,
  100 : #c1c5e2,
  200 : #989fcf,
  300 : #6e79bc,
  400 : #4f5cad,
  500 : #303f9f,
  600 : #2b3997,
  700 : #24318d,
  800 : #1e2983,
  900 : #131b72,
  A100 : #a8aeff,
  A200 : #757eff,
  A400 : #424fff,
  A700 : #2937ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$warn-palette: (
  50 : #f3e0e0,
  100 : #e1b3b3,
  200 : #cd8080,
  300 : #b94d4d,
  400 : #aa2626,
  500 : #9b0000,
  600 : #930000,
  700 : #890000,
  800 : #7f0000,
  900 : #6d0000,
  A100 : #ff9c9c,
  A200 : #ff6969,
  A400 : #ff3636,
  A700 : #ff1d1d,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$low-palette: (
  50 : #e6f9e7,
  100 : #c0efc3,
  200 : #96e49b,
  300 : #6cd973,
  400 : #4dd155,
  500 : #2dc937,
  600 : #28c331,
  700 : #22bc2a,
  800 : #1cb523,
  900 : #11a916,
  A100 : #d9ffda,
  A200 : #a6ffa8,
  A400 : #73ff76,
  A700 : #59ff5e,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$config: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
);
$primary: mat.define-palette($seak-palette);
$accent: mat.define-palette(mat.$amber-palette);
$secondary: mat.define-palette(mat.$amber-palette);
$tertiary: mat.define-palette($tertiary-palette);
$warn: mat.define-palette($warn-palette);
$low: mat.define-palette($low-palette);
$theme: mat.define-light-theme(
  (
    typography: $config,
    color: (
      primary: $primary,
      secondary: $secondary,
      tertiary: $tertiary,
      accent: $accent,
      low: $low,
      warn: $warn,
    )
  )
);

.full-width {
  width: 100%;
}

form.wave div.wave-spinner {
  height: 24px;
}

safe-spinner.wave-12 div.wave-spinner {
  height: 12px;
  margin: 0;
}

mat-paginator {
  margin-top: 1.0rem;
}

@mixin map-marker {
  cursor: pointer;
  background-size: cover;
}

@mixin map-marker-border {
  border: 2px solid #0a5a62;
  background-color: rgba(255, 255, 255, 0.6);
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.alert-marker-critical {
  @include map-marker;
  @include map-marker-border;
  background-image: url("/assets/images/map-alert-critical.png");
}

.alert-marker-moderate, .alert-marker-low {
  @include map-marker;
  @include map-marker-border;
  background-image: url("/assets/images/map-alert.svg");
}

.checkin-marker {
  @include map-marker;
  @include map-marker-border;
  background-image: url("/assets/images/map-checkin.svg");
  margin: auto;
}

.facility-marker {
  @include map-marker;
  @include map-marker-border;
  background-image: url("/assets/images/map-facility.svg");
}

.livelocation-marker {
  @include map-marker;
  @include map-marker-border;
  background-image: url("/assets/images/map-livelocation.svg");
  margin: auto;
}

div.device-label {
  text-align: center;
  background-color: #e2eced;
  border-style: solid;
  border-width: 1px;
  border-color: #0a5a62;
  color: #06464d;
  padding: 3px 6px;
  margin-top: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 16px;
}

div.latest {
  color: #085057;
  font-size: 90%;
  margin: 0;
  padding: 0;
}

div.popup {
  padding: 0;
  h2.subheading {
    color: #085057;
    font-size: 80%;
    margin: 0;
  }
  h2.subheading.critical {
    color: mat.get-color-from-palette($warn);
  }
  h3.heading {
    margin: 0 0 4px 0;
    font-size: 120%;
    border: none;
    a {
      text-decoration: none;
      border: none;
      color: mat.get-color-from-palette($primary, 700);
    }
  }
  div.device {
    color: #085057;
    font-size: 90%;
    font-weight: bold;
    margin: 0;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-image: url("/assets/images/safe-background.jpg");
  background-size: cover;
  background-repeat: repeat;
}

@include mat.all-component-themes($theme);

a[mat-mdc-button], a[mat-stroked-button], button[mat-mdc-button], button[mdc-button], button[mat-raised-button], button[mat-stroked-button], button[mat-ripple], .mat-mdc-tab.mdc-tab, label mat-label {
  letter-spacing: normal !important;
}

mat-card-header.full-width {
  width: 100%;
}

mat-chip-listbox.user-group-member {
  button.mat-mdc-chip-action {
    .mat-mdc-chip-action-label, .mat-mdc-chip-graphic svg, .mat-mdc-chip-trailing-icon {
      color: mat.get-color-from-palette($primary, 800);
    }
  }
}

div.mat-mdc-tab.live.mdc-tab--active {
  span.mdc-tab__text-label {
    color: mat.get-color-from-palette($warn);
  }
  span.mdc-tab-indicator__content {
    border-color: mat.get-color-from-palette($warn);
  }
}

mat-datepicker-toggle button {
  margin-right: 0.6em;
  color: mat.get-color-from-palette($primary) !important;
}

mat-chip-listbox.threat-level {
  mat-chip-option.mat-mdc-chip-selected.low, mat-chip-option.mat-mdc-chip-highlighted.low {
    background-color: mat.get-color-from-palette($low, 700);
  }
  mat-chip-option.mat-mdc-chip-selected.moderate, mat-chip-option.mat-mdc-chip-highlighted.moderate {
    background-color: #e7b416;
    .mat-mdc-chip-action-label, .mat-mdc-chip-graphic svg, .mat-mdc-chip-trailing-icon {
      color: black;
    }
  }
  mat-chip-option.mat-mdc-chip-selected.critical, mat-chip-option.mat-mdc-chip-highlighted.critical {
    background-color: mat.get-color-from-palette($warn);
  }
}

mat-chip-listbox.reminder {
  mat-chip-option {
    .mat-mdc-chip-action-label, span.mat-mdc-chip-graphic svg, .mat-mdc-chip-trailing-icon {
      color: mat.get-color-from-palette($primary, 800) !important;
    }
  }
}

mat-card-header.business-card > .mat-mdc-card-header-text {
  width: 100%;
}

mat-chip-listbox.alert-threat-level {
  mat-chip-option.mat-mdc-chip-selected.low, mat-chip-option.mat-mdc-chip-highlighted.low {
    background-color: mat.get-color-from-palette(mat.$amber-palette, 400);
    .mat-mdc-chip-action-label, .mat-mdc-chip-graphic svg, .mat-mdc-chip-trailing-icon {
      color: black;
    }
  }
  mat-chip-option.mat-mdc-chip-selected.moderate, mat-chip-option.mat-mdc-chip-highlighted.moderate {
    background-color: mat.get-color-from-palette(mat.$amber-palette, 500);
    .mat-mdc-chip-action-label, .mat-mdc-chip-graphic svg, .mat-mdc-chip-trailing-icon {
      color: black;
    }
  }
  mat-chip-option.mat-mdc-chip-selected.critical, mat-chip-option.mat-mdc-chip-highlighted.critical {
    background-color: mat.get-color-from-palette($warn);
  }
}

mat-chip-option.responder {
  span.mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
  }

  span.mat-mdc-chip-graphic {
    display: none;
  }

  mat-chip-option.order {
    span.mat-mdc-chip-graphic {
      display: flex;
    }
    width: fit-content;
    margin-left: 0;
    margin-right: 0.5rem;
    padding-right: 1rem;
  }
}

mat-chip-row.facility {
  span.mat-mdc-chip-action-label {
    display: flex;
  }

  span.mat-mdc-chip-graphic {
    display: none;
  }

  mat-icon.mat-mdc-chip-avatar {
    margin: 0 0.6em;
  }
}

mat-chip-row.userGroup, mat-chip-option.userGroup {
  span.mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
  }

  span.mat-mdc-chip-graphic {
    display: none;
  }

  mat-chip-option {
    span.mat-mdc-chip-graphic {
      display: flex;
    }

    button.mat-mdc-chip-action {
      padding-right: 0 !important;
    }
  }
}

mat-select.recipientType .mat-mdc-select-arrow-wrapper {
  align-items: end;
}

safe-spinner.wave-12 {
  transform: scale(0.4, 0.3);
}

.mat-mdc-icon-button {
  display: flex;
  justify-content: center;
}

.backdrop {
  background-color: #bbbbbbf2;
  backdrop-filter: blur(4px);
}

.warning-panel {
  background-color: #ff000044;
}

div.highlight em {
  font-weight: bold;
  font-style: normal;
}

span.highlight em {
  font-weight: bold;
  font-style: normal;
}

.mat-column {
  display: flex;
  flex-direction: column;
}

.mat-column.gap-1em {
  gap: 1em;
}

.mat-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mat-line.start {
  justify-content: start;
}

.mat-line.center {
  justify-content: center;
}

.mat-line.top {
  align-items: start;
}

.mat-line.bottom {
  align-items: end;
}

.mat-line.gap-1em {
  gap: 1em;
}

.mat-line.gap-0-5em {
  gap: 0.5em;
}

.mat-line.space-between {
  justify-content: space-between;
}

.mat-line.start {
  justify-content: start;
}

.mat-line.end {
  justify-content: end;
}

google-map.live-location, google-map.check-in, google-map.facility, google-map.global-dashboard {
  div.marker-label {
      text-align: center;
      background-color: #e2eced;
      border-style: solid;
      border-width: 1px;
      border-color: #0a5a62;
      color: #06464d;
      padding: 3px 6px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      border-radius: 16px;
    }
}

div.trail-selector mat-expansion-panel div.mat-expansion-panel-body {
  padding: 2px 24px;
}

div.trail-selector mat-expansion-panel mat-list.mat-mdc-list-base {
  padding-top: 0;
  mat-list-item.mat-mdc-list-item {
    height: 36px;
  }
}

button.mat-mdc-menu-item > span {
  width: 100%;
  mat-radio-button.full-width {
    width: 100%;
    div.mdc-form-field {
      width: 100%;
      label {
        width: 100%;
      }
    }
  }
}

google-map.live-alerts > div.map-container {
  border: 1px solid black;
  margin-bottom: 1em;
  overflow: visible;
}

mat-icon.alert span.mat-badge-content {
  background-color: mat.get-color-from-palette($warn);
}

mat-card mat-card-subtitle.primary {
  color: mat.get-color-from-palette($primary);
}

mat-list.map-key.mat-mdc-list-base > mat-list-item > span.mat-mdc-list-item-content {
  padding: 0 8px;
}

mat-card mat-card-header.safe-business-logo div.mat-mdc-card-header-text {
  width: 100%;
}

mat-option span.title {
  font-weight: bold;
  font-size: 1em;
}

mat-option span.description {
  font-size: 0.9em;
  color: grey;;
}

safe-user-avatar img { transition: all .3s ease-in-out; cursor: pointer; }

mat-card.popup {
  position: absolute;
  z-index: 2000;
  div.mat-mdc-card-header-text {
    flex-grow: 1;
  }
}

.cdk-overlay-container {
  position: fixed;
  z-index: 10000;
}

fieldset {
  border: 1px solid #e0e0e0;
}

div.top-menu safe-user-avatar img {
  margin-top: 4px;
  margin-bottom: 4px;
}

safe-my-live-location, safe-my-check-ins {
  google-map > div.map-container {
    border: 1px solid mat.get-color-from-palette($primary);
  } 
}

div.drop-zone {
  height: 36px;
  margin: auto;
  border: 2px dotted mat.get-color-from-palette($primary);
  border-radius: 30px;
}

div.drop-zone-content {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: mat.get-color-from-palette($primary);
}
